<template>
  <div class="wrap">
    <div class="common-layout">
      <el-container>
        <el-header>
          <el-form :model="formState">
            <el-row :gutter="20">
              <el-col :xs="8" :sm="6" :md="2" :lg="2" :xl="2">
                <el-form-item class="w-100">
                  <el-autocomplete
                      v-model="formState.country"
                      :fetch-suggestions="onSearchCountry"
                      clearable
                      class="w-100"
                      placeholder="Ru"
                      @select="login"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="16" :sm="18" :md="8" :lg="8" :xl="8">
                <el-form-item class="w-100">
                  <el-input
                      class="w-100"
                      v-model="formState.searchQuery"
                      placeholder="Query"
                      clearable
                      @change="disableChange"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item>
                  <el-button-group>
                    <el-button type="primary" :disabled="disable" @click="onSubmit">Search</el-button>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="`Pages: ${currentPage}/${totalPages}`"
                        placement="bottom"
                    >
                      <el-button type="primary" :disabled="!hasNextPage || (currentPage === totalPages)"
                                 @click="loadMoreApps">Load more
                      </el-button>
                    </el-tooltip>

                    <el-popconfirm
                        title="Are you sure to delete search results?"
                        @confirm="clearResult">
                      <template #reference>
                        <el-button :disabled="results.length === 0" type="danger" :icon="Delete"></el-button>
                      </template>
                    </el-popconfirm>
                  </el-button-group>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                <el-alert v-if="error" title="error alert" type="error"/>
              </el-col>
            </el-row>
          </el-form>
        </el-header>
        <el-main>
          <el-empty v-if="results.length === 0"/>
          <el-collapse v-if="results.length !== 0" v-model="resultsTableCollapseMap" @change="handleChangeCollapseResult">
            <el-collapse-item v-for="({id, tableData}, index) in [...results].reverse()" :key="id" :title="`Result ${id}`" :name="index">
              <template #title>
                <div style="padding: 10px">
                  <el-badge :value="tableData.length" max="9999" class="item">
                    <b>{{ id }}</b>
                  </el-badge>
                </div>
              </template>
              <el-table v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column type="index"/>
                <el-table-column prop="icon" label="Icon" width="60">
                  <template #default="scope">
                    <div style="display: flex; align-items: center">
                      <el-avatar
                          :src="scope.row.icon"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="appid" label="ID" width="120"/>
                <el-table-column prop="name" label="Name"/>
                <el-table-column prop="sizeDesc" label="Size" width="100"/>
                <el-table-column prop="downCountDesc" label="Downloads" width="240"/>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";
import {
  Delete
} from '@element-plus/icons-vue'

export default {
  name: "MainPage",
  setup() {
    const apiUrl = process.env.VUE_APP_API_URL

    const authInfo = ref();
    const countries = ref([])
    const formState = ref({
      country: '',
      searchQuery: '',
    })
    const results = ref([])
    const currentPage = ref(1)
    const disable = ref(true)
    const loading = ref(false)
    const error = ref(false)
    const totalCountApps = ref(0)
    const totalPages = ref(0)
    const hasNextPage = ref(false)
    const resultsTableCollapseMap = ref(['1'])


    const parseApps = (data) => {
      totalCountApps.value = data['count']
      totalPages.value = data['totalPages']
      hasNextPage.value = !!data['hasNextPage']

      return data['layoutData']
          .filter((el) => {
            return (el.layoutName === 'safeappcard' || el.layoutName === 'searchappcard')
          })
          .reduce(
              (accumulator, currentValue) => {
                return [...accumulator, ...currentValue['dataList']
                    .map(({name, icon, appid, downCountDesc, sizeDesc}) => ({
                      name,
                      icon,
                      appid,
                      downCountDesc,
                      sizeDesc
                    }))]
              },
              []
          )
    }

    const getCountries = () => {
      disable.value = true
      loading.value = true
      fetch(`${apiUrl}/countries`)
          .then(response => response.json())
          .then((data) => {
            countries.value = data
                .map((el) => ({id: el.name, value: el.countryCode}))
            disableChange()
            loading.value = false
          })
          .catch(() => {
            disableChange()
            loading.value = false
            error.value = true
          })
    }

    //TODO может вынести в onMounted?
    getCountries()

    const login = () => {
      disable.value = true
      loading.value = true
      fetch(`${apiUrl}/login?lang=${formState.value.country.length > 0 ? formState.value.country : 'RU'}`)
          .then(response => response.json())
          .then((data) => {
            authInfo.value = data['sign']
            disableChange()
            loading.value = false
          })
          .catch(() => {
            disableChange()
            loading.value = false
            error.value = true
          })
    }
    const getApps = () => {
      loading.value = true
      fetch(`${apiUrl}/search?page=${currentPage.value}&search=${formState.value.searchQuery}&sign=${authInfo.value}`)
          .then(response => response.json())
          .then((response) => {
            const id = `${formState.value.country}:${formState.value.searchQuery}`
            const index = results.value.length > 0 ? results.value.findIndex(value => {
              return value.id === id
            }) : -1
            const data = parseApps(response)

            if (results.value[index]) {
              results.value[index] = {
                id: id,
                tableData: [...results.value[index].tableData, ...data]
              }
            } else {
              results.value.push({
                id: `${formState.value.country}:${formState.value.searchQuery}`,
                tableData: data
              })
            }

            loading.value = false
          })
          .catch(() => {
            disableChange()
            error.value = true
            loading.value = false
          })
    }

    const disableChange = () => {
      disable.value = (
          formState.value.searchQuery.length === 0 ||
          formState.value.country.length === 0
      ) && error.value
    }


    const createFilter = (queryString) => {
      return (country) => {
        return (
            country.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    }

    const onSearchCountry = (queryString, cb) => {
      const results = queryString
          ? countries.value.filter(createFilter(queryString))
          : countries.value
      cb(results)
    }

    const loadMoreApps = () => {
      currentPage.value++
      getApps()
    }

    const onSubmit = () => {
      disable.value = true
      currentPage.value = 1
      getApps()
    }

    const clearResult = () => {
      results.value = []
    }

    const handleChangeCollapseResult = () => {

    }

    return {
      resultsTableCollapseMap,
      onSearchCountry,
      formState,
      onSubmit,
      results,
      countries,
      login,
      disable,
      disableChange,
      loading,
      error,
      totalCountApps,
      totalPages,
      hasNextPage,
      loadMoreApps,
      currentPage,
      clearResult,
      handleChangeCollapseResult,
      Delete
    }
  }
}
</script>

<style scoped lang="scss">

.wrap {
  margin: -8px;
  padding: 8px;
  position: relative;
}

.wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-image: url('@/assets/img/background.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.common-layout {
  position: relative;
  opacity: 50;
  height: 100vh;
  padding: 8px;
  margin: -8px;
}
</style>
